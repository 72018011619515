<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Categories'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Category Create'"
      :title="'Category Create'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Submit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>

          <p class="form-create__label">SEO Name (for URL)</p>
          <div class="input-container">
            <InputText
              :onChange="setSeoName"
              :id="'seoName'"
              :label="'seo name'"
              :value="formDataToSend.seo_name"
              :type="'text'"
              :placeholder="'SEO Name'"
              :error-messages="validationErrors['seoName']"
              isBordered
            />
          </div>

          <p class="form-create__label">Image to home page</p>

          <div class="input-container">
            <div class="form-create__images">
              <div
                v-for="(image, index) in formDataToSend.img"
                :key="image.id"
                class="form-create__image"
              >
                <picture-input
                  v-if="formDataToSend.img[index].file == null"
                  :ref="`pictureInput_${index}`"
                  @change="handleChoosePhoto(index)"
                  accept="image/jpeg,image/png"
                  size="100"
                  width="220"
                  height="220"
                  :name="`image_${index}`"
                  buttonClass="change-image__button"
                  :customStrings="{ drag: '+', change: 'Change' }"
                />
                <img
                  v-if="formDataToSend.img[index].file != null"
                  :id="`blah_${index}`"
                  src="#"
                  class="form-create__image-load"
                  alt="load"
                />
                <div
                  @click="handleChangeImage(index)"
                  v-if="formDataToSend.img[index].file != null"
                  class="form-create__change-image"
                >
                  <RestoreIcon />
                </div>
                <div
                  v-if="index != 0"
                  @click="handleDeleteImage(index)"
                  class="form-create__delete-image"
                >
                  <CancelIcon />
                </div>
              </div>
              <div @click="handleAddImage" class="form-create__add-image">
                <CancelIcon />
              </div>
            </div>

            <Notification :error-messages="validationErrors['images']" />
          </div>

          <p class="form-create__label">H1</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'h1'"
              :label="'h1'"
              :type="'text'"
              :placeholder="'H1'"
              :error-messages="validationErrors['h1']"
              isBordered
            />
          </div>

          <p class="form-create__label">Title</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'title'"
              :label="'title'"
              :type="'text'"
              :placeholder="'Title'"
              :error-messages="validationErrors['title']"
              isBordered
            />
          </div>

          <p class="form-create__label">Description</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'description'"
              :label="'description'"
              :type="'text'"
              :placeholder="'Description'"
              :max-length="'255'"
              :error-messages="validationErrors['description']"
              isBordered
            />
          </div>
          <div class="input-container">
            <MainCheckbox :label="'Is Active'" :id="'active'" :set-value="formDataToSend" />
          </div>

          <div class="input-container">
            <MainCheckbox
              :label="'Show in top menu'"
              :id="'show_in_menu'"
              :set-value="formDataToSend"
            />
          </div>

          <div class="input-container">
            <MainCheckbox
              :label="'Show in main page'"
              :id="'show_main'"
              :set-value="formDataToSend"
            />
          </div>

          <p class="form-create__label">Annotation</p>

          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'annotation'"
              :label="'annotation'"
              :placeholder="''"
              :error-messages="validationErrors['annotation']"
              isBordered
            />
          </div>

          <p class="form-create__label">SEO Text</p>
          <div class="input-container">
            <TextEditor
              :set-value="formDataToSend"
              :id="'seo_text'"
              :placeholder="''"
              :error-messages="validationErrors['seo_text']"
            />
          </div>

          <p class="form-create__label">Youtube video</p>
          <div class="form-create__sizes">
            <div class="form-create__sizes-item">
              <p class="form-create__label">Title video</p>
            </div>
            <div class="form-create__sizes-item">
              <p class="form-create__label">Video code</p>
            </div>
          </div>

          <div
            v-for="(item, index) in formDataToSend.videos"
            :key="item.id"
            class="form-create__sizes"
          >
            <div class="form-create__sizes-item">
              <InputText
                :set-value="formDataToSend.videos[index]"
                :id="'title'"
                :label="'title'"
                :type="'text'"
                :placeholder="''"
                isBordered
              />
            </div>
            <div class="form-create__sizes-item">
              <InputText
                :set-value="formDataToSend.videos[index]"
                :id="'url'"
                :label="'url'"
                :type="'text'"
                :placeholder="''"
                isBordered
              />
            </div>
            <div
              v-if="index != 0"
              @click="handleDeleteRowVideo(index)"
              class="form-create__sizes-delete"
            >
              <CancelIcon />
            </div>
          </div>
          <div class="form-create__add-size">
            <MainButton @click.native="handleAddRowVideo">Add more videos</MainButton>
          </div>

          <p class="form-create__label">Anchor title</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'anchor_title'"
              :label="'anchor_title'"
              :type="'text'"
              :placeholder="'Anchor title'"
              :error-messages="error['anchorTitle']"
              isBordered
            />
          </div>

          <div class="form-create__radio-group">
            <div class="form-create__radio-group-item">
              <p class="form-create__label">FAQ</p>
              <div class="checkbox-container checkbox-container_margin">
                <div v-for="item in faqGroups" :key="item.id" class="checkbox-container__item">
                  <MainCheckbox
                    :label="item.name"
                    :id="item.id + ''"
                    :set-value="formDataToSend.faq_group"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-create__radio-group">
            <div class="form-create__radio-group-item">
              <p class="form-create__label">Category Group</p>
              <div class="input-container checkbox-container">
                <InputRadio
                  :set-value="formDataToSend"
                  :id="'groups'"
                  :values="categoriesGroupsTree"
                  :name="'name'"
                  :value="'id'"
                />
              </div>
            </div>
            <div class="form-create__radio-group-item">
              <p class="form-create__label">Parent Category</p>
              <div class="input-container checkbox-container">
                <InputCategory
                  :set-value="formDataToSend"
                  :id="'parent_category'"
                  :values="categoriesTree"
                  :name="'name'"
                  :value="'id'"
                />
              </div>
            </div>
          </div>

          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
        <MainModal
          v-if="errorModal"
          :onClose="
            () => {
              errorModal = false;
            }
          "
          modalCenter
        >
          <div class="error-img">{{ errorModalText }}</div></MainModal
        >
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import { seoTransform } from "~/helpers/seoTransform";
import { categorySend } from "~/helpers/dataIteration";
import PictureInput from "vue-picture-input";
import categoriesApi from "~/api/categories";
import faqApi from "~/api/faq";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import InputTextarea from "~/components/atoms/inputs/InputTextarea.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import InputRadio from "~/components/atoms/inputs/InputRadio.vue";
import InputCategory from "~/components/atoms/inputs/InputCategory";
import TextEditor from "~/components/atoms/inputs/TextEditor.vue";
import CancelIcon from "~/assets/images/icons/cancel-icon.svg";
import RestoreIcon from "~/assets/images/icons/restotre.svg";
import Notification from "~/components/molecules/Notification.vue";
import MainModal from "~/components/molecules/MainModal.vue";

export default {
  name: "CategoryCreate",
  metaInfo: {
    title: "Category Create",
  },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      faqGroups: [],
      formDataToSend: {
        name: "",
        seo_name: "",
        h1: "",
        title: "",
        description: "",
        active: false,
        show_in_menu: false,
        show_main: false,
        annotation: "",
        anchor_title: "",
        parent_category: null,
        groups: null,
        faq_group: {},
        seo_text: "",
        videos: [
          {
            title: "",
            url: "",
            id: 3234234234,
          },
        ],
        img: [
          {
            id: 0,
            file: null,
          },
        ],
        imgUrl: [
          {
            url: null,
          },
        ],
      },
      errorModal: false,
      errorModalText: "",
    };
  },
  components: {
    MainButton,
    InputText,
    ModalError,
    InputTextarea,
    MainCheckbox,
    InputRadio,
    InputCategory,
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    TextEditor,
    PictureInput,
    CancelIcon,
    RestoreIcon,
    Notification,
    MainModal,
  },
  mounted() {
    this.getGroups();
    this.getCategoriesTree();
    this.getCategoriesGroupsTree();
  },
  computed: {
    ...mapState("categories", [
      "error",
      "loadingProcess",
      "validationErrors",
      "categoriesTree",
      "categoriesGroupsTree",
    ]),
    getFirstLink() {
      return ROUTE.CATEGORIES_LIST.replace(":page", 1);
    },
  },
  methods: {
    handleSubmit() {
      const data = categorySend(this.formDataToSend);
      let images = [];
      if (this.formDataToSend.imgUrl.length > 0) {
        const arrayImages = this.formDataToSend.imgUrl;
        for (let i = 0; arrayImages.length > i; i++) {
          if (arrayImages[i].imageId != null) {
            images.push(arrayImages[i].imageId);
          }
        }
      }
      if (images.length > 0) {
        data.images = images;
      }
      const groups = Object.keys(this.formDataToSend.faq_group);
      if (groups.length) {
        let groupsSend = [];
        for (let i = 0; groups.length > i; i++) {
          if (this.formDataToSend.faq_group[groups[i]] == true) {
            groupsSend.push(groups[i]);
          }
        }
        if (groupsSend.length > 0) {
          data.faq_groups = groupsSend;
        }
      }

      const videosSend = [];
      const videos = this.formDataToSend.videos;
      for (let i = 0; videos.length > i; i++) {
        if (videos[i].url.length > 0) {
          videosSend.push(videos[i]);
        }
      }
      if (this.formDataToSend.videos.length > 0) {
        data.videos = videosSend;
      } else {
        data.videos = [];
      }

      this.$store.dispatch("categories/createCategory", data);
    },
    getCategoriesTree() {
      this.$store.dispatch("categories/getCategoriesTree");
    },
    getCategoriesGroupsTree() {
      this.$store.dispatch("categories/getCategoriesGroupsTree");
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
      this.setSeoName(value);
    },
    setSeoName(data) {
      const value = seoTransform(data);
      this.formDataToSend.seo_name = value;
      this.handleCheckSeoName();
    },
    handleCheckSeoName() {
      if (this.formDataToSend.seo_name.length > 0) {
        const url = `/${this.formDataToSend.seo_name.trim()}`;
        this.$store.dispatch("categories/categoryCheckSeo", url);
      }
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.CATEGORIES_LIST.replace(":page", 1),
      });
    },
    handleChoosePhoto(index) {
      setTimeout(() => {
        this.formDataToSend.img[index].file = document.querySelector(
          `input[name="image_${index}"]`,
        ).files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
          document.getElementById(`blah_${index}`).setAttribute("src", e.target.result);
        };

        reader.readAsDataURL(this.formDataToSend.img[index].file);

        this.$store.commit("categories/setLoading", true);
        const data = new FormData();
        data.append("image", this.formDataToSend.img[index].file);
        categoriesApi
          .categoriesImagesUpload(data)
          .then((res) => {
            this.formDataToSend.imgUrl[index].url = Object.values(res.data)[0].fileName;
            this.formDataToSend.imgUrl[index].imageId = Object.values(res.data)[0].imageId;
          })
          .catch((error) => {
            this.formDataToSend.img[index].file = null;
            this.errorModal = true;
            this.errorModalText = "You can't upload images. Error";
          })
          .finally(() => {
            this.$store.commit("categories/setLoading", false);
          });
      }, 0);
    },
    handleAddImage() {
      if (
        this.formDataToSend.img.length == 1 ||
        this.formDataToSend.img[this.formDataToSend.img.length - 1].file != null
      ) {
        this.formDataToSend.img.push({
          file: null,
          id: Math.floor(Math.random() * 100),
        });
        this.formDataToSend.imgUrl.push({
          url: null,
        });
      }
    },
    handleDeleteImage(index) {
      if (this.formDataToSend.imgUrl[index].url != null) {
        this.$store.commit("templates/setLoading", true);
        const data = {
          fileName: this.formDataToSend.imgUrl[index].url,
        };
        categoriesApi
          .categoriesImagesDelete(data)
          .then((res) => {
            if (res.data.deleted) {
              this.formDataToSend.img.splice(index, 1);
              this.formDataToSend.imgUrl.splice(index, 1);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      } else {
        this.formDataToSend.img.splice(index, 1);
        this.formDataToSend.imgUrl.splice(index, 1);
      }
    },

    handleChangeImage(index) {
      this.$store.commit("templates/setLoading", true);
      const data = {
        fileName: this.formDataToSend.imgUrl[index].url,
      };
      categoriesApi
        .categoriesImagesDelete(data)
        .then((res) => {
          if (res.data.deleted) {
            this.formDataToSend.img[index].file = null;
            this.formDataToSend.imgUrl[index].url = null;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit("templates/setLoading", false);
        });
    },
    getGroups() {
      try {
        faqApi.getFaqGroups().then((res) => {
          const data = res.data.items;
          this.faqGroups = data;
          this.setGroups(data);
        });
      } catch (error) {
        console.log(error);
      }
    },
    setGroups(data) {
      const groups = data;
      for (let i = 0; groups.length > i; i++) {
        this.$set(this.formDataToSend.faq_group, groups[i].id, false);
      }
    },

    handleDeleteRowVideo(index) {
      this.formDataToSend.videos.splice(index, 1);
    },
    handleAddRowVideo() {
      this.formDataToSend.videos.push({
        title: "",
        url: "",
        id: Math.floor(Math.random() * 10000),
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.form-create {
  &__radio-group {
    display: flex;
  }

  &__radio-group-item {
    width: 50%;
  }

  /deep/.textarea-input {
    textarea {
      height: 240px;
      resize: vertical;
    }
  }

  .checkbox-container {
    @include scrollBar;

    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 20px;
    margin-right: 100px;
  }

  &__images {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  &__image {
    position: relative;
    width: 220px;
    height: 220px;
    margin: 0 30px 45px 0;
    border: 1px solid $mainDisabled;
  }

  &__delete-image {
    position: absolute;
    top: -19px;
    right: -26px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  &__add-image {
    position: absolute;
    top: -19px;
    right: -8px;
    cursor: pointer;
    transform: rotate(45deg);

    svg {
      path {
        fill: $mainSecondColor;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__image-load {
    width: 220px;
    height: 220px;
    object-fit: cover;
  }

  &__change-image {
    position: absolute;
    bottom: -40px;
    left: 44%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: $mainColor;
    border-radius: 50px;
    cursor: pointer;

    svg {
      path {
        fill: $g-color-white;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
.checkbox-container {
  @include scrollBar;

  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;

  &_margin {
    margin-top: 20px;
  }
}
.checkbox-container__item {
  padding: 5px 0;
}
</style>
